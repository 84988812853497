body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

#root {
    display: flex;
    width: 100vw;
    height: 100vh;
}
